import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import Awards from '../sections/awards'
import GreenBar from '../sections/greenBar4'
import { PageHead } from '../common'

export default () => (
  <Layout>
    <SEO
      title="Underwoods Awards"
      description="Clayton Bromberg, President of Underwood’s, is a 6th generation jeweler and the recipient of many industry awards including the most prestigious AGS, Robert M. Shipley award." />
    <PageHead>
      <h1>UNDERWOOD'S AWARDS</h1>
      <p>Clayton Bromberg is a 6th generation jeweler and has received many awards for his contributions to the industry. Underwood’s has been acknowledged nationally and locally for many achievements from organizations such as the Women’s Jewelry Association, the American Gem Society, Jacksonville’s Folio Weekly and more. We strive to be the best for our services in consumer protection and are proud to be recognized by such prestigious establishments.</p>
    </PageHead>
    <GreenBar />
    <Awards />
    <JoinUs />
  </Layout>
)
