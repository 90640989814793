import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { colors, md } from '../common'

export default () => (
  <StaticQuery
    query={graphql`
      query AwardQuery {
        allPrizeJson {
          edges {
            node {
              text
              title
            }
          }
        }
      }
      `}
      render={ data => (
        <Wrap>
          {data.allPrizeJson.edges.map((award, index) => {
            return  <Card key={index}>
                      <img css={`margin-bottom:-40px;`} src="https://s3.amazonaws.com/underwoods/Awards/icon-awards-green.png" alt="award icon" />
                      <p css={`color:${colors.green};font-size:20px;text-transform:uppercase;`}>{award.node.title}</p>
                      <br />
                      <div dangerouslySetInnerHTML={{__html:award.node.text}} />
                    </Card>
          })}
        </Wrap>
      )}
  />
)

const Card = styled.div`
  width: 26%;
  text-align:center;
  margin: .5rem;
  background-color:white;
  padding: 2rem;
  font-size:14px;
  color: ${colors.grey};
  line-height: 20px;

  ${md} {
    width:100%;
  }
`

const Wrap = styled.div`
  background-color: ${colors.greyLight};
  padding: 4rem;
  display:flex;
  flex-wrap: wrap;
  justify-content:space-around;

  ${md} {
    padding: 1rem;
  }
`
